/* eslint-disable no-undef */
const courseSettings = {
  language: "fi",
  name: "Miten talous toimii?",
  siteUrl: "https://talouskurssi.fi/",
  githubUrl: "https://github.com/rage/miten-talous-toimii/",
  slug: "miten-talous-toimii",
  tmcCourse: "miten-talous-toimii",
  quizzesId: "a2e9030e-e9aa-4472-9af7-6d2703f9edae",
  courseId: "7af975e8-1e35-427d-99bf-12865b90e2b5",
  tmcOrganization: "hy",
  bannerPath: "banner.svg",
  theme: "indigoTheme",
  alternativeQuizLabel: "",
  courseVariants: [
    {
      organization: "Laurea",
      slug: "miten-talous-toimii-laurea",
      title: "Laurea AMK: Liiketoimintaosaaminen",
      fullName: "Laurea-ammattikorkeakoulu"
    },
    {
      organization: "HAMK",
      slug: "miten-talous-toimii-hamk",
      title: "HAMK: Liiketoimintaosaaminen",
      fullName: "Hämeen ammattikorkeakoulu"
    },
  ],
}

module.exports = courseSettings
